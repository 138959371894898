// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
import 'bootstrap';
import 'popper.js';
import 'select2';
import LocalTime from 'local-time';
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('channels');
require('@creative-tim-official/argon-dashboard-free/assets/js/argon.min');
require('packs/abraham');
require('packs/form_helpers');
require('packs/webdriver_form_helper.js');
require('jquery-validation');
require('jquery-validation/dist/additional-methods');

LocalTime.start();

window.fadeOutAlerts = function () {
  var $alertsContainer = $('#alerts-container');
  $alertsContainer.find('.alert').fadeOut(10000);
};

window.resizeCards = function () {
  var ww = document.body.clientWidth;
  if (ww > 2200) {
    $('.js-responsive-card').addClass('col-xl-2');
  } else if (ww < 1550) {
    $('.js-responsive-card').addClass('col-xl-6');
  } else {
    $('.js-responsive-card').addClass('col-xl-3');
  }
};

// Used by TestScenario and Team
window.showNameInput = function () {
  $('#edit-name').hide();
  const $input = $('#entity-name');
  const oldName = $input.val();
  $input[0].type = 'text';
  $input.focus().val('').val(oldName);

  $input.focusout(() => submitName($input, oldName));

  $input.keydown(function (e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      submitName($input, oldName);
      $input.blur();
    }
  });

  function submitName($input, oldName) {
    if (oldName === $input.val() || !$input.val().trim()) {
      $input.val(oldName);
      $input[0].type = 'hidden';
      $('#edit-name').show();
    } else {
      oldName = $input.val();
      $('#edit-name-form-btn').click();
    }
  }
};

window.onload = function () {
  window.fadeOutAlerts();
  window.resizeCards();
  const $nameInput = $('.name-input');

  $nameInput.on('keypress', (event) => {
    const key = String.fromCharCode(
      !event.charCode ? event.which : event.charCode
    );
    checkName(event, key);
  });

  $nameInput.on('paste', (event) => {
    const pasteText = event.originalEvent.clipboardData.getData('Text');
    checkName(event, pasteText);
  });

  const checkName = (event, key) => {
    const regex = new RegExp('[<>%$]+');
    if (regex.test(key)) {
      $nameInput
        .tooltip({ placement: 'bottom', trigger: 'manual' })
        .tooltip('show');
      setTimeout(() => {
        $nameInput.tooltip('hide');
      }, 3000);
      event.preventDefault();
    }
  };

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-tooltip-toggle="tooltip"]').tooltip();
  });

  $('.custom-file-input').on('change', function (e) {
    var fileName = e.target.files[0].name;
    $(this).parent().children().first().text(fileName);

    // Set the _destroy attribute to false
    $(this).closest('.input-group').find('.destroy-field').val('false');

    // Show the delete icon if the file input is not empty
    $(this).closest('.input-group').find('.delete-icon').parent().parent().removeClass('d-none');
  });

  $('.delete-icon').on('click', function () {
    // Clear the file input
    $(this).closest('.input-group').find('.extension-input').val('');

    // Set the _destroy attribute to true
    $(this).closest('.input-group').find('.destroy-field').val('true');

    // Clear the file name
    $(this).closest('.input-group').find('.custom-file-label').text('Upload browser extension');

    // Hide the delete icon
    $(this).hide();
  });




  Abraham.startTour('intro');
};

window.initSwiper = (element, params) => {
  const swiper = new Swiper(element, {
    ...params,
    modules: [Navigation, Pagination, EffectFade],
  });

  return swiper;
};

window.saveUserTimezone = () => {
  // Check if the timezone is already set
  if ($('#user_timezone').length === 0 || $('#user_timezone').val() === "") {
    var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    $.ajax({
      url: '/users/update_timezone',
      type: 'POST',
      dataType: 'json',
      headers: {
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      data: {
        user: { timezone: timezone }
      },
      success: function (response) {
        // Handle success
      },
      error: function (xhr, status, error) {
        // Handle error
      }
    });
  }
}
